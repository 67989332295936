import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import {
  useTracking,
  type TrackingEvent,
} from '@xing-com/crate-communication-tracking';
import { EmptyStateLayout } from '@xing-com/crate-components-empty-state';
import { useStatusCode, statusCodes } from '@xing-com/crate-xinglet';
import { SingleSizeIllustrationErrorBigErrorPage500 } from '@xing-com/illustrations';

type NoAccessProps = {
  className?: string;
  errorStatusCode?: number;
};

type NoAccessTrackingType = TrackingEvent<unknown> & {
  PropTrackAction: 'not_allowed_error_page_view';
};

const Illustration = styled(SingleSizeIllustrationErrorBigErrorPage500)`
  width: 100%;
`;

const backAction = 'https://www.xing.com/';

export const NoAccessPage: React.FunctionComponent<NoAccessProps> = ({
  className,
  errorStatusCode = statusCodes.unauthorized,
}) => {
  const { formatMessage } = useIntl();

  useStatusCode(errorStatusCode);

  const { track } = useTracking<NoAccessTrackingType>();

  React.useEffect(() => {
    track({
      type: 'interaction',
      event: 'PropTrackAction',
      PropTrackAction: 'not_allowed_error_page_view',
    });
  }, [track]);

  return (
    <EmptyStateLayout
      button={
        <p>
          <a href={backAction}>
            <FormattedMessage id="SYSM_WEB_ERROR_CTA" />
          </a>
        </p>
      }
      className={className}
      headline={formatMessage({ id: 'CONTENT_ERROR_404_HEADLINE' })}
      image={<Illustration />}
      subline={formatMessage({ id: 'CONTENT_ERROR_404_SUBLINE' })}
    />
  );
};
