import type * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { EmptyStateLayout } from '@xing-com/crate-components-empty-state';
import { useStatusCode, statusCodes } from '@xing-com/crate-xinglet';
import { SingleSizeIllustrationErrorBigErrorPage404 } from '@xing-com/illustrations';

type NotFoundProps = {
  className?: string;
  errorStatusCode?: number;
};

const Illustration = styled(SingleSizeIllustrationErrorBigErrorPage404)`
  width: 100%;
`;

const backAction = 'https://www.xing.com/';

export const NotFound: React.FunctionComponent<NotFoundProps> = ({
  className,
  errorStatusCode = statusCodes.notFound,
}) => {
  const { formatMessage } = useIntl();

  useStatusCode(errorStatusCode);

  return (
    <EmptyStateLayout
      button={
        <p>
          <a href={backAction}>
            <FormattedMessage id="SYSM_WEB_ERROR_CTA" />
          </a>
        </p>
      }
      className={className}
      headline={formatMessage({ id: 'CONTENT_ERROR_404_HEADLINE' })}
      image={<Illustration />}
      subline={formatMessage({ id: 'CONTENT_ERROR_404_SUBLINE' })}
    />
  );
};
