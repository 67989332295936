import type * as React from 'react';

import type { Xinglet } from '@xing-com/crate-xinglet';

import { Generic } from './generic';
import { NoAccessPage as NoAccess } from './no-access';
import { NotFound } from './not-found';

const errorComponents = {
  Generic,
  NoAccess,
  NotFound,
};

type Props = {
  className?: string;
  errorType: keyof typeof errorComponents;
  errorStatusCode?: number;
};

const Component: React.FC<Props> = ({
  className,
  errorType,
  errorStatusCode,
}) => {
  const ErrorComponent = errorComponents[errorType];
  return (
    <ErrorComponent className={className} errorStatusCode={errorStatusCode} />
  );
};

export default class ErrorPages implements Xinglet {
  public getComponent(): React.FC<Props> {
    return Component;
  }
}
