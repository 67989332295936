import type * as React from 'react';
import styled from 'styled-components';

import {
  spaceS,
  spaceL,
  spaceXL,
  xdlColorText,
  xdlColorTextSecondary,
} from '@xing-com/tokens';
import { Headline, Meta } from '@xing-com/typography';

const Container = styled.div`
  flex: 1;
  padding: 0 ${spaceL};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 54px);
`;

const ImageContainer = styled.div`
  display: inline-flex;
  padding-bottom: ${spaceXL};
`;

const StyledHeadline = styled(Headline)<{ $variant: EmptyStateVariants }>`
  && {
    color: ${({ $variant }) =>
      $variant === 'empty' ? xdlColorText : xdlColorTextSecondary};
    margin-bottom: ${spaceS};
    text-align: center;
  }
`;

const StyledMeta = styled(Meta)<{ $variant: EmptyStateVariants }>`
  && {
    text-align: center;
    color: ${({ $variant }) =>
      $variant === 'empty' ? xdlColorText : xdlColorTextSecondary};
  }
`;

const ButtonContainer = styled.div`
  padding-top: 32px;
`;

export type EmptyStateVariants = 'default' | 'empty';

type EmptyStateLayoutProps = {
  className?: string;
  image?: React.ReactElement;
  headline?: string;
  subline?: string;
  button?: React.ReactElement;
  dataQa?: string;
  variant?: EmptyStateVariants;
};

export const EmptyStateLayout: React.VFC<EmptyStateLayoutProps> = ({
  className,
  image,
  headline,
  subline,
  button,
  dataQa,
  variant = 'default',
}) => (
  <Container className={className} data-qa={dataQa}>
    {image && <ImageContainer>{image}</ImageContainer>}
    {headline && (
      <StyledHeadline size="xlarge" $variant={variant}>
        {headline}
      </StyledHeadline>
    )}
    {subline && (
      <StyledMeta noMargin $variant={variant}>
        {subline}
      </StyledMeta>
    )}
    {button && <ButtonContainer>{button}</ButtonContainer>}
  </Container>
);
