import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { EmptyStateLayout } from '@xing-com/crate-components-empty-state';
import { useStatusCode, statusCodes } from '@xing-com/crate-xinglet';
import { SingleSizeIllustrationErrorBigErrorPage500 } from '@xing-com/illustrations';

type GenericProps = {
  className?: string;
  errorStatusCode?: number;
};

const Illustration = styled(SingleSizeIllustrationErrorBigErrorPage500)`
  width: 100%;
`;

export const Generic: React.FunctionComponent<GenericProps> = ({
  className,
  errorStatusCode = statusCodes.internalServerError,
}) => {
  const { formatMessage } = useIntl();

  useStatusCode(errorStatusCode);

  return (
    <EmptyStateLayout
      button={
        <p>
          <Button
            variant="primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            {formatMessage({ id: 'BUTTON_TRY_AGAIN' })}
          </Button>
        </p>
      }
      className={className}
      headline={formatMessage({ id: 'XE_GENERIC_ERROR' })}
      image={<Illustration />}
    />
  );
};
